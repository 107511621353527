import React from 'react'
import mixpanel from 'mixpanel-browser'
import axios from 'axios';
import Cookies from 'js-cookie';


export async function setMixpanelUserProfile(userProfileData) {

    // Retrieve UTM parameters from cookies
    

    const distinct_id = Cookies.get('mixpanelDistinctId');
    try {
        const response = await axios.post(`${process.env.NEXT_PUBLIC_MIXPANEL_API_BASE_URL}engage/?data=${encodeURI(JSON.stringify({
            $token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
            $distinct_id: distinct_id,
            $set: userProfileData,
        }))}&sig=${getMixpanelSignature()}`);

        // console.log('Mixpanel API response:', response.data);
        return response.data;
    } catch (error) {
        console.log('Error setting Mixpanel user profile:', error);
        // throw new Error('Error setting Mixpanel user profile');
    }
}



function getMixpanelSignature() {
    const timestamp = Math.floor(Date.now() / 1000);
    const signature = require('crypto').createHash('md5')
        .update(`${timestamp}${process.env.NEXT_PUBLIC_MIXPANEL_TOKEN}`)
        .digest('hex');
    return `${timestamp}${signature}`;
}


export const initalizeTractEvents = (is_track) => {
    if (!mixpanel.__loaded) {
        mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
            track_pageview: is_track,
            persistence: 'localStorage',
        });
        // console.log('Mixpanel initialized');
    }

    const distinctId = mixpanel.get_distinct_id();
    Cookies.set('mixpanelDistinctId', distinctId);
};

export const ResetUser = () => {

    try {
        mixpanel.reset();
        mixpanel.people.unset('email');
        mixpanel.people.unset('name');
        mixpanel.people.unset('phone');
        mixpanel.people.unset('id');
    } catch (ex) {
        console.log("Mixpanel reset user issue");
    }
}


export const TrackEvents = (event, properties = {}) => {
    const storedUTMParams = JSON.parse(Cookies.get('utm_params') || '{}');
    const trackingProperties = {
        ...properties,
        macmerise_utm_source: storedUTMParams.source || "N/A",
        macmerise_utm_medium: storedUTMParams.medium || "N/A",
        macmerise_utm_campaign: storedUTMParams.campaign || "N/A",
        macmerise_utm_term: storedUTMParams.term || "N/A",
        macmerise_utm_content: storedUTMParams.content || "N/A",
    };

    try {
        if (mixpanel && typeof mixpanel.track === 'function') {
            mixpanel.track(event, trackingProperties);
        } else {
            console.error("Mixpanel is not initialized or track function is missing");
        }
    } catch (ex) {
        console.log("Mixpanel track event issue", ex);
    }
};



// export const TrackEventsAPI = async (event, properties = {}) => {
//     const distinct_id = Cookies.get('mixpanelDistinctId');
//     let data;
//     if (distinct_id)
//         data = {
//             event,
//             properties: {
//                 token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
//                 distinct_id: distinct_id,
//                 ...properties,
//             },
//         };
//     else
//         data = {
//             event,
//             properties: {
//                 token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
//                 ...properties,
//             },
//         };

//     try {

//         if (event === "$identify") //login
//         {

//             await setMixpanelUserProfile(distinct_id, properties);

//         }
//         else {
//             const response = await axios.post(
//                 process.env.NEXT_PUBLIC_MIXPANEL_API_URL + encodeURIComponent(JSON.stringify(data))
//             );
//             // console.log('Event tracked:', response.data);
//         }


//     } catch (error) {
//         console.error('Error tracking event:', error);
//     }
// }




