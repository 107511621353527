import React, { useState, useEffect, useMemo } from "react";
import Wrapper from "./Wrapper";
import Link from "next/link";

import { useSelector } from "react-redux";
import MobileMenu from "./MobileMenu";
import WebMenu from "./WebMenu";
import useClickOutside from "@/hooks/useClickOutside";
import useMediaQuery from "@/utils/mediaQuery";
import Cookies from "js-cookie";
import { useRef } from "react";
import { cn } from "@/lib/tailwind-utils";
import Icons from "./icons";
import { motion, useScroll, useMotionValueEvent } from "framer-motion";

import { ALGOLIA_SEARCH_INDEX, searchClient } from "@/lib/algolia";

import { Highlight } from "react-instantsearch";
// import "instantsearch.css/themes/satellite.css";
import {
  Hits,
  InstantSearch,
  SearchBox,
  Configure,
  useInstantSearch,
} from "react-instantsearch";
import { useRouter } from "next/router";
import { AnimatePresence } from "framer-motion";

export const Hit = ({ hit, onMouseLeave }) => {
  // console.log(hit);
  return (
    <article className="relative w-full px-4 py-4" onClick={onMouseLeave}>
      <a
        className="absolute inset-0"
        href={`${process.env.NEXT_PUBLIC_HOST}/product/${hit.handle}`}
      />

      <Highlight attribute="title" hit={hit} />
    </article>
  );
};

const Search = ({ handleMouseLeave, isOpenState }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const router = useRouter();

  const [client, _] = useState({
    ...searchClient,
    search(requests) {
      setSearchQuery(requests[0].params.query);
      const shouldSearch = requests.some(
        ({ params: { query } }) => query !== ""
      );
      if (shouldSearch) {
        return searchClient.search(requests);
      }
      return Promise.resolve({
        results: [{ hits: [] }],
      });
    },
  });

  const handleSearch = (e) => {
    if (searchQuery === "") return;
    // router.push(`/results?q=${searchQuery}`);
    // /results?products%5Bquery%5D=a
    router.push(`/results?${ALGOLIA_SEARCH_INDEX}%5Bquery%5D=${searchQuery}`);
    // handleMouseLeave();
  };

  if (!isOpenState) return null;
  return (
    <div className="max-w-6xl mx-auto">
      <InstantSearch searchClient={client} indexName={ALGOLIA_SEARCH_INDEX}>
        <Configure hitsPerPage={10} query="" />
        <SearchBox
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (searchQuery.length !== 0) {
                router.push(
                  `/results?${ALGOLIA_SEARCH_INDEX}%5Bquery%5D=${searchQuery}`
                );
              }
            }
          }}
          autoFocus
          placeholder="Search for products..."
          classNames={{
            form: "w-full bg-transparent",
            input:
              "shadow-none bg-transparent border-0 border-b border-brand-charcoal/10 focus:border-brand-charcoal/20 rounded-none placeholder:text-brand-charcoal/80 text-brand-charcoal w-full",
          }}
        />
        <button
          type="button"
          onClick={handleSearch}
          disabled={searchQuery === ""}
          className={
            "w—full text-center bg-brand-nebula lg:hidden block cursor-pointer font-bold hover:border-brand-nebula mb-2 py-2 rounded-[5px] text-sm text-white transition-transform uppercase disabled:opacity-80 disabled:cursor-not-allowed"
          }
          style={{ width: "100%" }}
        >
          Search
        </button>
        <div className="ais-InstantSearch-- max-h-96 overflow-y-scroll scrollbar-hide rounded-md">
          {/* <Hits hitComponent={<Hit />} classNames={{}} className="" /> */}

          <NoResultsBoundary fallback={<NoResults />}>
            <Hits
              hitComponent={(data) => {
                // hit.sendEvent(hit);
                return <Hit hit={data.hit} onMouseLeave={handleMouseLeave} />;
              }}
            />
          </NoResultsBoundary>
        </div>
      </InstantSearch>
    </div>
  );
};

function NoResultsBoundary({ children, fallback }) {
  const { results } = useInstantSearch();

  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned.
  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    );
  }

  return children;
}

function NoResults() {
  const { indexUiState } = useInstantSearch();

  return (
    <div className="text-brand-charcoal">
      <p>
        No results for <q>{indexUiState.query}</q>.
      </p>
    </div>
  );
}

const Header = ({ menuItems }) => {
  const router = useRouter();
  const navRef = useRef(null);
  const isMediumScreen = useMediaQuery("md");
  const [mobileMenu, setMobileMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const { scrollY } = useScroll();
  const [isSticky, setIsSticky] = useState(false);
  const [searchScrollThreshold, setSearchScrollThreshold] = useState(0);

  const token = Cookies.get("mmtoken");
  const { cartItems } = useSelector((state) => state.cart);
  const subTotal = useMemo(() => {
    return (
      cartItems.reduce(
        (total, obj) => total + Math.round(obj.quantity * 100),
        0
      ) / 100
    );
  }, [cartItems]);

  const toggleSearch = () => {
    setIsOpen((prevState) => !prevState);
  };
  useClickOutside(navRef, () => {
    setIsOpen(false);
  });

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 150) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }

    // if search is open, close it when scrolling with threshold of 150 from current scroll position

    if (isOpen) {
      if (searchScrollThreshold.start === 0) {
        // Set the thresholds when search is first opened
        setSearchScrollThreshold({
          start: latest - 250,
          end: latest + 250,
        });
      } else if (
        latest < searchScrollThreshold.start ||
        latest > searchScrollThreshold.end
      ) {
        // Close search when scrolled past thresholds in either direction
        setIsOpen(false);
        setSearchScrollThreshold({ start: 0, end: 0 });
      }
    } else {
      // Reset thresholds when search is closed
      setSearchScrollThreshold({ start: 0, end: 0 });
    }

    // Sticky header logic
    if (latest > 150) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  });

  // prevent scroll when mobile menu is open
  useEffect(() => {
    if (mobileMenu && isMediumScreen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }

    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [mobileMenu, isMediumScreen]);

  useEffect(() => {
    router.events.on("routeChangeComplete", () => {
      setIsOpen(false);
    });
    return () => {
      router.events.off("routeChangeComplete", () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);

  return (
    <>
      <motion.header
        variants={{
          visible: { y: 0 },
          hidden: { y: "-100%" },
        }}
        initial="visible"
        animate={isVisible ? "visible" : "hidden"}
        transition={{ duration: 0.35, ease: "easeInOut" }}
        data-search={isOpen}
        ref={navRef}
        className={cn(
          // isOpen ? "bg-brand-charcoal" : "bg-white",
          // isOpen
          //   ? "[--light:#101010] [--dark:#f9f9f9]"
          //   : "[--light:#f9f9f9] [--dark:#101010]",
          "[--light:#f9f9f9] [--dark:#101010]",
          "group transition-all animate-in fade-in duration-200",
          // "has-[[data-active=true]]:bg-brand-charcoal",
          // "has-[[data-active=true]]:border-b has-[[data-active=true]]:border-b-white/30",
          // "has-[[data-active=true]]:[--light:#101010] has-[[data-active=true]]:[--dark:#f9f9f9]",
          // "fixed top-0 left-0 right-0 z-50"
          "sticky top-0 z-50 bg-white",
          isSticky ? "border-b border-gray-200" : ""
        )}
      >
        <Wrapper className="px-5 lg:px-10">
          <nav className="flex items-center">
            {/* Mobile Nav */}
            <button
              onClick={() => {
                setMobileMenu((prevState) => !prevState);
                // setMobileMenu2(true);
                // // close menu if open
                // if (mobileMenu) {
                //   setMobileMenu(false);
                // }
                // close search if open
                if (isOpen) {
                  setIsOpen(false);
                }
              }}
              className="inline-grid place-items-center lg:hidden"
            >
              <Icons.IconMenu className="size-6 text-brand-charcoal --group-data-[search=true]:text-white" />
            </button>

            {/* Logo */}

            <Link
              href="/"
              className="shrink-0 inline-grid place-items-center flex-1 lg:flex-auto min-h-[60px] lg:h-auto lg:max-w-[160px]"
            >
              <Icons.Logo className="cursor-pointer ml-8 lg:ml-auto h-auto max-w-[160px]" />
            </Link>

            {/* Web Menu */}
            <WebMenu
              menuItems={menuItems}
              className="lg:flex-1 --group-has-[[data-active=true]]:text-white --group-data-[search=true]:text-white hidden lg:flex gap-5"
              onHoverDisableSearch={() => {
                setIsOpen(false);
              }}
            />

            {/* Actions */}
            <div className="flex items-center gap-2">
              {/* Search */}
              <button
                onClick={toggleSearch}
                className="text-brand-charcoal --group-has-[[data-active=true]]:text-white --group-data-[search=true]:text-white"
              >
                <Icons.IconSearch className="size-6" />
              </button>

              {/* User :Hidden on mobile*/}
              <Link
                href={
                  token
                    ? isMediumScreen
                      ? "/account"
                      : "/account/dashboard"
                    : "/customer/login"
                }
                className="text-brand-charcoal --group-has-[[data-active=true]]:text-white --group-data-[search=true]:text-white hidden lg:block"
              >
                <Icons.IconUser className="size-6" />
              </Link>

              {/* Wishlist :Hidden on mobile*/}
              <Link
                href="/account/mywishlist"
                className="text-brand-charcoal --group-has-[[data-active=true]]:text-white --group-data-[search=true]:text-white hidden lg:block"
              >
                <Icons.IconHeart className="size-6" />
              </Link>

              {/* Cart */}
              <Link
                href="/cart"
                className="relative text-brand-charcoal --group-has-[[data-active=true]]:text-white --group-data-[search=true]:text-white"
              >
                <Icons.IconShoppingCart className="size-6" />
                {subTotal > 0 && (
                  <span className="absolute -top-1.5 -right-1 bg-red-500 text-white rounded-full text-xs grid place-items-center aspect-square size-4">
                    {subTotal}
                  </span>
                )}
              </Link>
            </div>
          </nav>
        </Wrapper>
        {mobileMenu && (
          <MobileMenu
            menuItems={menuItems}
            setMobileMenu={setMobileMenu}
            isMobileMenuOpen={mobileMenu}
          />
        )}
        {/* SearchBar starts */}
        <AnimatePresence>
          {isOpen && (
            // .search-box.open {
            //   position: absolute;
            //   top: 40px;
            //   z-index: 99;
            //   background: #000;
            //   width: 100%;
            // }

            // .search-box.closed {
            //   max-height: 0;
            //   max-width: 768px;
            //   margin: 0 auto;
            //   display: none;
            // }
            <motion.div
              className={cn(
                "absolute inset-x-0 top-[60px] lg:top-[80px] z-50 bg-white w-full",
                isOpen ? "h-full" : "h-0"
              )}
              initial={{ y: "-100%", opacity: 0 }}
              animate={{ y: "0%", opacity: 1 }}
              exit={{ y: "-100%", opacity: 0 }}
              transition={{ type: "spring", stiffness: 300, damping: 25 }}
            >
              <div className="w-full px-4 pb-4 lg:p-[30px] bg-white">
                <Search handleMouseLeave={toggleSearch} isOpenState={isOpen} />
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* SearchBar ends */}
      </motion.header>
      {/* <div className="h-[60px] lg:h-[80px]" />{" "} */}
      {/* Spacer to prevent content from being hidden under the header */}
    </>
  );
};

export default Header;
