import { CONST_MENU } from "@/utils/constants";

async function fetchMenuData() {
  try {
    const response = await fetch(CONST_MENU);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const res = await response.json();
    return res.data;
  } catch (ex) {
    console.error("Error fetching menu data:", ex.message);
    return [];
  }
}

export default fetchMenuData;
