import axios from 'axios';
import Cookies from 'js-cookie';
import UAParser from 'ua-parser-js';


// Function to gather context information
const gatherContextInfo = () => {
  const parser = new UAParser();
  const browserInfo = parser.getBrowser();
  const osInfo = parser.getOS();
  const deviceInfo = parser.getDevice();

  // Retrieve and parse location data from cookies
  const locationCookie = Cookies.get('location');
  const location = locationCookie ? JSON.parse(locationCookie) : {};
  // Get the distinctId from cookies
  const mixpanelDistinctId = Cookies.get('mixpanelDistinctId');
  let deviceId = '';
  if (mixpanelDistinctId && mixpanelDistinctId.includes(':')) {
    deviceId = mixpanelDistinctId.split(':')[1];
  } else {
    deviceId = mixpanelDistinctId; 
  }




  return {
    $browser: browserInfo.name,
    $browser_version: browserInfo.version,
    $os: osInfo.name,
    $os_version: osInfo.version,
    $device_type: deviceInfo.type || 'mobile',
    $screen_height: window.screen.height,
    $screen_width: window.screen.width,
    $current_url: window.location.href,
    $referrer: document.referrer,
    $referring_domain: document.referrer ? new URL(document.referrer).hostname : '$direct',
    $library_version: '2.55.1',
    $mixpanel_library: 'web',
    $device_id: deviceId,
    $initial_referrer: Cookies.get('initial_referrer') || '$direct',
    $initial_referring_domain: Cookies.get('initial_referring_domain') || '$direct',
    // $insert_id: generateUUID(),
    $time: Math.floor(Date.now() / 1000),
    $city: location.city || 'Unknown',
    mp_country_code: location.country || 'Unknown',
    $country_flag: location.flag || 'Unknown',
    $country_region: location.countryRegion || 'Unknown',
    $latitude: location.latitude || 'Unknown',
    $longitude: location.longitude || 'Unknown',
  };
};

const generateDeviceId = () => {
  let deviceId = localStorage.getItem('device_id');
  if (!deviceId) {
    deviceId = generateUUID();
    localStorage.setItem('device_id', deviceId);
  }
  return deviceId;
};

const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

// Main tracking function
export const trackMixpanelEvent = async (event, properties = {}) => {
  const contextInfo = gatherContextInfo();
  const storedUTMParams = JSON.parse(Cookies.get('utm_params') || '{}');

  const trackingProperties = {
    ...contextInfo,
    ...properties,
    macmerise_utm_source: storedUTMParams.source || "N/A",
    macmerise_utm_medium: storedUTMParams.medium || "N/A",
    macmerise_utm_campaign: storedUTMParams.campaign || "N/A",
    macmerise_utm_term: storedUTMParams.term || "N/A",
    macmerise_utm_content: storedUTMParams.content || "N/A",
  };


  try {
    const response = await axios.post('/api/track-event', { event, properties: trackingProperties });
    console.log('Event tracked server-side:', response.data);
  } catch (error) {
    console.error('Error tracking event server-side:', error);
  }
};

// Set initial referrer and referring domain when the page loads
if (typeof window !== 'undefined') {
  if (!Cookies.get('initial_referrer')) {
    Cookies.set('initial_referrer', document.referrer || '$direct');
    Cookies.set('initial_referring_domain', document.referrer ? new URL(document.referrer).hostname : '$direct');
  }
}

// Function to initialize tracking (call this on app initialization)
export const initializeTracking = () => {
  const distinctId = generateDeviceId();
  Cookies.set('mixpanelDistinctId', distinctId);
};
