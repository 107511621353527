// MobileMenu.js
import React, { useState, useEffect } from "react";
import { CONST_MENU } from "@/utils/constants";
import SubmenuFullscreen from "./SubmenuFullscreen";
import MobileStaticLinks from "./MobileStaticLinks";
import { IoMdClose } from "react-icons/io";
import { FiChevronRight } from "react-icons/fi";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { TrackEvents } from "@/analytics-utils/eventTracker";
import Cookies from "js-cookie";
import Icons from "./icons";
import { cn } from "@/lib/tailwind-utils";
import useMediaQuery from "@/utils/mediaQuery";

import { trackMixpanelEvent } from "@/analytics-utils/mixpanle-serverside";
const MobileMenu = ({ setMobileMenu, menuItems }) => {
  // const [menuItems, setMenuItems] = useState([]);
  const [CategoryItems, setCategoryItems] = useState([]);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(true);
  const [isBackClicked, setIsBackClicked] = useState(false);
  const [isCloseClicked, setIsCloseClicked] = useState(false);

  const isMediumScreen = useMediaQuery("md");

  const router = useRouter();
  const location = useSelector((state) => state.location.location);
  const user = useSelector((state) => state.user);

  const handleSubmenuClick = (selectedItem) => {
    setSelectedItem(selectedItem);
  };

  const handleBack = () => {
    setSelectedItem(null);
    setIsBackClicked(true);
  };

  const handleCloseMobileMenu = () => {
    setSelectedItem(null);
    setIsMobileMenuOpen(() => false);
    setIsCloseClicked(() => true);
    setMobileMenu(false);
  };
  const handleCloseAndLogoutMobileMenu = () => {
    handleCloseMobileMenu();
    Cookies.remove("mmtoken");

    trackMixpanelEvent("LogOut", {...user });
    localStorage.removeItem("usr");
    const logOutDetails = {
      event_category: 'User Interaction',
      event_action: 'LogOut',
      event_label: 'User LogOut',
      user_id: user?.id || "N/A",
      user_name: user?.name || "N/A",
      user_email: user?.email || "N/A", 
      location: location || "N/A",
    };
  
    // Replace `sendGAEvent` with your GA4 tracking function

    router.push("/customer/login");
  };
  const handleLink = (lnk) => {
    handleCloseMobileMenu();
    window.location = lnk;
  };

  useEffect(() => {
    const token = Cookies.get("mmtoken");
    if (token) setIsLoggedin(() => true);
    else setIsLoggedin(() => false);
  }, []);

  useEffect(() => {
    const webMenu = async () => {
      try {
        if (menuItems.length > 0) {
          const unique = [...new Set(menuItems.map((item) => item.category))];
          setCategoryItems(unique);
        }
      } catch (ex) {
        setCategoryItems([]);
        console.log(ex.message);
      }
    };
    webMenu();
  }, [menuItems]);

  // perform cleanup
  useEffect(() => {
    return () => {
      setSelectedItem(null);
      setIsMobileMenuOpen(false);
      setIsBackClicked(false);
      setIsCloseClicked(false);
    };
  }, []);

  // hide when url changes
  useEffect(() => {
    const handleRouteChange = () => {
      handleCloseMobileMenu();
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  return (
    <div>
      {isMobileMenuOpen && (
        <div className="">
          <div
            // className={`${isMobileMenuOpen ? "animate-top" : ""}
            // ${isCloseClicked ? "animate-bottom" : ""}
            // lg:hidden fixed inset-0 bg-white z-50 h-dvh overflow-y-auto `}
            className={cn(
              "lg:hidden fixed inset-0 bg-white z-50 h-dvh overflow-y-auto",
              isMobileMenuOpen ? "animate-top" : "",
              isCloseClicked ? "animate-bottom" : ""
            )}
          >
            <div className={`relative ${isBackClicked ? "FadeInLeft" : ""} `}>
              <div className="flex items-center justify-between min-h-[60px] sticky top-0 bg-white px-[15px] z-[9] border-b border-b-brand-charcoal/30">
                <button className="size-5 invisible pointer-events-none"></button>
                <div className="w-[33%]-- justify-center items-center [--light:#f9f9f9] [--dark:#101010]">
                  <Icons.Logo className="cursor-pointer h-auto max-w-[160px]" />
                </div>
                <button
                  className="text-brand-charcoal focus:outline-none"
                  onClick={handleCloseMobileMenu}
                >
                  <IoMdClose className="text-[20px] text-brand-charcoal" />
                </button>
              </div>
              <ul className="px-[15px] ul-one pt-[15px]">
                {CategoryItems.map((menuItem, index) => (
                  <li key={index}>
                    <button
                      className="w-full avenir-black font-semibold pr-4 text-brand-charcoal text-left text-lg mb-[15px] capitalize relative"
                      onClick={() => handleSubmenuClick(menuItem)}
                    >
                      {menuItem}{" "}
                      <FiChevronRight className="absolute right-0 top-[2px] text-[18px] text-brand-charcoal" />
                    </button>
                  </li>
                ))}
                {/* <li>
                  <Link
                    href="/list/tote-bags"
                    onClick={handleCloseMobileMenu}
                    className="w-full pr-4 text-brand-charcoal text-left text-[18px] mb-[15px] capitalize font-[900] "
                  >
                    Bags & Wallets
                  </Link>
                </li> */}
              </ul>
              {/* Other Navigations Starts */}
              <ul className="px-[15px] ul-two">
                <li className="leading-normal text-xs capitalize text-brand-dark-gray avenir-medium pr-4 mb-[10px] mt-[48px]">
                  Account
                </li>

                {isLoggedin ? (
                  <>
                    <MobileStaticLinks
                      className="pl-0"
                      link={isMediumScreen ? "/account" : "/account/dashboard"}
                      title={"My Account"}
                      handleCloseMobileMenu={handleCloseMobileMenu}
                    ></MobileStaticLinks>
                    <MobileStaticLinks
                      className="pl-0"
                      link={"/account/mywishlist"}
                      title={"My Wishlist"}
                      handleCloseMobileMenu={handleCloseMobileMenu}
                    ></MobileStaticLinks>
                  </>
                ) : (
                  <MobileStaticLinks
                    className="pl-0"
                    link={"/customer/login"}
                    title={"Login"}
                    handleCloseMobileMenu={handleCloseMobileMenu}
                  ></MobileStaticLinks>
                )}
                <li className="leading-normal text-xs capitalize text-brand-dark-gray avenir-medium pr-4 mb-[10px] mt-[48px]">
                  Shop
                </li>
                <MobileStaticLinks
                  className="pl-0"
                  link={"/shopbyproducts"}
                  title={"Shop By Products"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>
                <MobileStaticLinks
                  className="pl-0"
                  link={"/shopbybrands"}
                  title={"Shop By Brands"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>
                <MobileStaticLinks
                  className="pl-0"
                  link={"/shopbycollection"}
                  title={"Shop By Collection"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>
                <MobileStaticLinks
                  className="pl-0"
                  link={"/shopbythemes"}
                  title={"Shop By Theme"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>
                <MobileStaticLinks
                  className="pl-0"
                  link={"/shopbycreators"}
                  title={"Shop By Creators"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>
                <MobileStaticLinks
                  className="pl-0"
                  link={"/shopbyevents"}
                  title={"Shop By Events"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>
                <li className="leading-normal text-xs capitalize text-brand-dark-gray avenir-medium pr-4 mb-[10px] mt-[48px]">
                  Company
                </li>

                <MobileStaticLinks
                  className="pl-0"
                  link={"/ourstory"}
                  title={"Our Story"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>
                <MobileStaticLinks
                  className="pl-0"
                  link={"/workwithus"}
                  title={"Work With Us"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>
                <MobileStaticLinks
                  className="pl-0"
                  link={"/corporategifting"}
                  title={"Corporate Gifting"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>
                <MobileStaticLinks
                  className="pl-0"
                  link={"/blog"}
                  title={"Blog"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>
                {/* <MobileStaticLinks
                  className="pl-0"
                  link={"/brandchampions"}
                  title={"Brand Champions"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks> */}

                <li className="leading-normal text-xs capitalize text-brand-dark-gray avenir-medium pr-4 mb-[10px] mt-[48px]">
                  Support
                </li>

                <MobileStaticLinks
                  className="pl-0"
                  link={"/contact"}
                  title={"Contact Us"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>
                {/* <MobileStaticLinks
                className="pl-0"
                  link={"#"}
                  title={"Track Your Order"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks> */}
                <MobileStaticLinks
                  className="pl-0"
                  link={"/faq"}
                  title={"Frequently Asked Questions"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>
                <MobileStaticLinks
                  className="pl-0"
                  link={"/warranty"}
                  title={"Warranty Registration"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>

                <MobileStaticLinks
                  className="pl-0"
                  link={"/productguide"}
                  title={"Product Usage Guide"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>
                <MobileStaticLinks
                  className="pl-0"
                  link={"/check-gc-balance"}
                  title={"Check Gift Card Balance"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>
                <MobileStaticLinks
                  className="pl-0"
                  link={"/track-order"}
                  title={"Track Order"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>

                <li className="leading-normal text-xs capitalize text-brand-dark-gray avenir-medium pr-4 mb-[10px] mt-[48px]">
                  Policies
                </li>
                <MobileStaticLinks
                  className="pl-0"
                  link={"/privacypolicy"}
                  title={"Privacy Policy"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>

                <MobileStaticLinks
                  className="pl-0"
                  link={"/terms"}
                  title={"Terms & Conditions"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>

                <MobileStaticLinks
                  className="pl-0"
                  link={"/storepolicies"}
                  title={"Store Policies"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>

                <MobileStaticLinks
                  className="pl-0"
                  link={"/returnandrefundpolicy"}
                  title={"Return & Refund Policies"}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                ></MobileStaticLinks>
              </ul>
              <div className="sticky bottom-0 p-[15px] bg-white mt-[25px] border-t border-brand-charcoal/30">
                {!isLoggedin ? (
                  <div className="relative">
                    {/* <Image
                unoptimized
                        width={24}
                        height={24}
                        alt="Profile"
                        className="bg-brand-dust-green rounded-[48px]"
                        src="https://images.macmerise.com/macmerise2.0/icons/profile_vector.png"
                      /> */}

                    <Icons.IconUser className="bg-brand-dust-green rounded-[48px] size-6" />

                    <Link
                      href="#"
                      onClick={() => handleLink("/customer/login")}
                      className="absolute top-0 left-[35px] text-brand-charcoal avenir-medium text-[12px]"
                    >
                      Login
                    </Link>
                    <span className="absolute top-0 left-[70px] text-brand-charcoal avenir-medium text-[12px]">
                      /
                    </span>
                    <Link
                      href="#"
                      onClick={() => handleLink("/customer/register")}
                      className="absolute top-0 left-[80px] text-brand-charcoal avenir-medium text-[12px]"
                    >
                      Sign Up
                    </Link>
                  </div>
                ) : (
                  <>
                    <div className="relative">
                      <Icons.IconUser className="bg-brand-dust-green rounded-[48px] size-6" />
                      <Link
                        href="#"
                        onClick={() => handleCloseAndLogoutMobileMenu()}
                        className="absolute top-0 left-[35px] text-brand-charcoal avenir-medium text-[12px]"
                      >
                        Logout
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedItem && (
        <SubmenuFullscreen
          subItems={menuItems.filter((item) => item.category === selectedItem)}
          onBack={handleBack}
          handleCloseMobileMenu={handleCloseMobileMenu}
        />
      )}
    </div>
  );
};

export default MobileMenu;
