import {
  CONST_NEW_ACCOUNT_CREATE,
  CONST_NEW_LOGIN_API,
  CONST_OTP,
  CONST_SIGNUP_VALIDATE,
  CONST_VALIDATE_OTP,
} from "@/utils/constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Centralized error messages for common status codes
const errorMessages = {
  400: "Bad Request. Please check your input.",
  401: "Unauthorized access. Please log in again.",
  403: "Forbidden. You don't have permission for this action.",
  404: "Resource not found. Please try again.",
  409: "Conflict. Please check your input.",
  500: "Server error. Please try again later.",
};

// Enhanced baseQuery with improved error handling
const baseQuery = async (args, api, extraOptions) => {
  // Define the fetchBaseQuery function with default settings
  const baseQueryFn = fetchBaseQuery({
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      // Add any custom headers, like authorization tokens, if necessary
      return headers;
    },
  });

  // Execute the base query
  const result = await baseQueryFn(args, api, extraOptions);

  // Check for errors in the response
  if (result.error) {
    const { status, data } = result.error;
    let errorMessage = "An unknown error occurred.";

    // Determine the error message based on status code or specific conditions
    errorMessage = data.message || errorMessages[status] || errorMessage;

    // Example: Customize error handling for specific endpoints
    if (args.url.includes(CONST_NEW_ACCOUNT_CREATE) && status === 409) {
      errorMessage =
        data.message ||
        "An account with this email or phone number already exists.";
    }

    // Log the error details for debugging purposes
    console.error(`Error in ${args.url}:`, {
      status,
      error: result.error,
      endpoint: args.url,
      payload: args.body,
    });

    // Return the customized error response
    return {
      error: {
        status,
        message: errorMessage,
        reason: data.reason || null, // Include specific reason provided by the server, if any
      },
    };
  }

  // Return the successful result
  return result;
};

// Define the API slice with enhanced baseQuery
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery, // Use the enhanced baseQuery
  endpoints: (builder) => ({
    sendOtp: builder.mutation({
      query: ({ type, value, isToggled = false }) => {
        const url = type === "email" ? CONST_NEW_LOGIN_API : CONST_OTP;
        const payload =
          type === "email"
            ? { email: value }
            : { phoneNumber: value, isToggled: isToggled };

        return {
          url: url, // Full URL is used directly
          method: "POST",
          body: payload,
        };
      },
    }),
    login: builder.mutation({
      query: ({ type, value }) => {
        const url = type === "email" ? CONST_NEW_LOGIN_API : CONST_OTP;
        const payload =
          type === "email"
            ? { email: value }
            : { phoneNumber: value.replace(/\+/g, "") };

        return {
          url: url, // Full URL is used directly
          method: "POST",
          body: payload,
        };
      },
    }),
    validateOtp: builder.mutation({
      query: ({ type, otp, value }) => {
        const url =
          type === "email" ? CONST_SIGNUP_VALIDATE : CONST_VALIDATE_OTP;
        const payload =
          type === "email"
            ? { email: value, otp }
            : { phoneNumber: value.replace(/\+/g, ""), otp };

        return {
          url: url, // Full URL is used directly
          method: "POST",
          body: payload,
        };
      },
    }),
    createAccount: builder.mutation({
      query: ({ email, firstName, lastName, phoneNumber }) => {
        const url = CONST_NEW_ACCOUNT_CREATE; // Full URL is used directly
        const payload = {
          email,
          firstName,
          lastName,
          phoneNumber: phoneNumber,
        };

        return {
          url: url, // Full URL is used directly
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
});

// Export hooks generated by RTK Query
export const {
  useSendOtpMutation,
  useLoginMutation,
  useValidateOtpMutation,
  useCreateAccountMutation,
} = apiSlice;

export default apiSlice.reducer;
