import { useEffect } from "react";
import { GoogleTagManager } from "@next/third-parties/google";

const GoogleTagManagerComponent = () => {
  useEffect(() => {
    // console.log("🚀 ~ useEffect ~ process.env.NEXT_PUBLIC_ENV:", process.env.NEXT_PUBLIC_ENV)

    if (process.env.NEXT_PUBLIC_ENV === "production") {
      // Inject the Google Tag Manager script dynamically
      const gtmScript = document.createElement("script");
      gtmScript.id = "gtm-script";
      gtmScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'GTM-NCWCW2KB');
      `;
      document.head.appendChild(gtmScript);

      // Inject the noscript tag for Google Tag Manager
      const noscript = document.createElement("noscript");
      noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NCWCW2KB" 
                height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.body.appendChild(noscript);
    }
  }, []);
    
  return process.env.NEXT_PUBLIC_ENV === "production" ? (
    <GoogleTagManager gtmId="GTM-NCWCW2KB" />
  ) : null;
};

export default GoogleTagManagerComponent;
