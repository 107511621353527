// lib/queryClient.js
import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  // mutationCache: new MutationCache({
  //   onSuccess: (_data, _variables, _context, mutation) => {
  //     // queryClient.invalidateQueries();
  //     // https://tkdodo.eu/blog/automatic-query-invalidation-after-mutations
  //     // queryClient.invalidateQueries({
  //     //   queryKey: mutation.options.mutationKey,
  //     // });
  //   },
  // }),
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 10, // 10 minutes
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});
